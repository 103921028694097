<!--
 * @Author: liu
 * @Date: 2021-03-31 11:22:33
 * @LastEditTime: 2021-04-01 16:25:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \cuip-nuxt\components\index\ScienceServices\SGoodItem.vue
-->
<template>

  <div>
    <div v-if="gType == 'SHOP_FLOOR'">
      <div class="per_gaoxiao" style="cursor: pointer;"
           @click="goStoreDetail(data.id)">
        <div class="touxiang_name">
          <div class="touxiang">
            <Avatar icon="ios-person" size="70" style="margin: 0 auto" :src="data.pic"/>
          </div>
          <div style="float: left;margin-left: 30px;margin-top: 10px" class="ellipse-1">
            <div style="font-size: 20px;font-weight: bold;color: rgb(40, 43, 69);margin-bottom: 10px;max-width: 200px;width: 180px" class="ellipse-1">
              {{data.name}}
            </div>
            <div style="text-align: left">
              <span style="color: #FDCE63">//////</span>
            </div>
          </div>
        </div>
        <div class="gaoxiao_intro">
            <span class="ellipse-7" style="height: 98%;width: 100%;display: -webkit-box;
            line-height: 26px;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
            white-space: normal !important;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical">
                    {{data.introduction || '暂无介绍'}}
            </span>


        </div>
      </div>
    </div>
    <div v-if="gType =='SHOP_EXPERT_FLOOR'">
      <div
          class="rwjs"
          @click="goExpertDetail(data.id)"
      >
        <div class="rwtx">
          <div style="width: 80px; margin: 40px auto">
            <Avatar
                icon="ios-person"
                size="70"
                style="margin: 0 auto"
                :src="data.pic"
            />
          </div>
        </div>

        <div
            class="rwjs_sub"
            style="
              float: left;
              height: 280px;
              width: 423px;
              box-sizing: border-box;
              padding-top: 40px;
              padding-bottom: 20px;
              padding-right: 20px;
            "
        >
          <div class="name_uni">{{ data.name }} <span style="display: inline-block;width: 2px;height: 16px;background-color: black"></span> {{ data.pa }}</div>
          <div class="subject">计算机科学与技术</div>
          <div style="text-align: left">
            <span style="color: #FDCE63">//////</span>
          </div>
          <div class="intro_detail ellipse-6" style="line-height: 26px;-webkit-line-clamp:5">
            {{ data.introduction || '暂无信息' }}
          </div>
        </div>
      </div>
    </div>
    <div v-if = "gType != 'SHOP_FLOOR' && gType != 'SHOP_EXPERT_FLOOR'" :class="type != 'lisense' ? 'good-con' : 'good-con lisense'" @click="goDetail(data.id)" style="height: 310px;width: 216px">
      <div v-if="type != 'patent'" style="height:196px;width:196px;flex-shrink: 0">
        <img :src="data.pic" style="width: 100%; height: 100%" />
      </div>
      <div class="good-price-con">
        <div :class="{bigger: gType === 'THIRD_PARTY_FLOOR'}" class="desc ellipse-2">
          {{ data.name }}
        </div>
        <div>
          <div v-if="gType === 'THIRD_PARTY_FLOOR'">
            <div class="dp-flex flex-between" style="align-items: baseline">
              <span style="color:#999999;font-size:14px">已为企业服务</span>
              <span style="color: #ff6a00; font-size: 23px;">{{ serviceNum }}<span style="font-size:18px">次</span></span>
            </div>
          </div>
          <div v-if="gType === 'PATENT_GOODS_FLOOR' || gType === 'SERVICE_GOODS_FLOOR2'">
            <div class="dp-flex flex-between good-price-info" style="align-items: baseline">
              <span style="color:#FF5200;font-size:23px;text-align: left"><span style="font-size:16px;">￥</span>{{ getNumSuffix(data.price) }}</span>
              <span class="ellips" style="color:#999999;font-size:14px;width: 120px;text-align: right" :title="data.classify">{{ data.classify }}</span>
            </div>
            <div class="go-buy" v-if="type != 'lisense'">立即购买</div> 
          </div>

          <!-- <div
            v-if="type === 'patent'"
            style="
              font-size: 12px;
              color: #666666;
              margin-top: 10px;
              text-align: left;
            "
          >
            <div>专利申请号：{{ data.patent.pnm }}</div>
            <div>专利类型：{{ data.patent.pnm }}</div>
            <div class="ellipse-1">店铺：{{ data.patent.pa }}</div>
          </div> -->
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "SGoodItem",
  mounted() {

  },
  props: {
    data: {
      type: Object,
      default: () => ({
        id: 0,
        name: "",
        subTitle: "",
        pic: "",
        price: 0,
        patent: null,
      }),
    },
    serviceNum: {
      type: String,
    },
    detailUrlPath: {
      type: String,
      default: "serviceDetail",
    },
    detailFullUrl: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "service",
    },
    gType: {
      type: String,
      default: "PATENT_GOODS_FLOOR",
    }
  },
  methods: {
    goStoreDetail(id) {
      this.$router.push({
            path: 'colleges',
            query: {
              shopId: id
            }
          }
      )
    },
    goDetail(id) {
      if(this.detailFullUrl) {
        this.$router.push({
          path: this.detailFullUrl
        });
      } else {
        this.$router.push({
          path: `${this.detailUrlPath}${id}`,
        });
      }
    },
    getNumSuffix(v) {
      const num = Number(v) || 0
      if(!num) return v
      if (num > 9999) {
        return Number((v / 10000).toFixed(1)) + '万'
      } else {
        return num
      }
    },
    goExpertDetail(id) {
      this.$router.push({
        path: "experts",
        query: {
          shopId: id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">

.rwjs {
  width: 575px;
  height: 280px;
  border: 1px solid #f5f5f5;
  flex-shrink: 0;
  cursor: pointer;
  margin-bottom: 20px;
}

.rwjs_sub div {
  margin-bottom: 10px;
}
.ivu-avatar>img {
  height: 100%!important;
}
.rwtx {
  width: 148px;
  float: left;
  height: 100%;
}

.name_uni {
  font-size: 20px;
  font-family: "Microsoft YaHei", serif;
  color: rgb(51, 51, 51);
  font-weight: bold;
}

.subject {
  font-size: 14px;
  font-family: "Microsoft YaHei", serif;
  color: rgb(51, 51, 51);
}

.zl_classify span {
  margin-right: 20px;
  line-height: 30px;
}

.intro_detail {
  font-size: 14px;
  font-family: "Microsoft YaHei", serif;
  color: rgb(51, 51, 51);
}

.per_gaoxiao:hover,.rwjs:hover, .good-con:hover {
  box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
}
.good-con {
  border: 1px solid rgba(232, 232, 232, 0.5);
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 4px;
  cursor: pointer;
}
.good-con:hover {
  box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
  &:not(.lisense) {
    .go-buy{
      display: block;
    }
    .good-price-info{
      display: none;
    }
  }
}
.go-buy{
  display: none;
  width: 100%;
  line-height: 32px;
  background: #1890FF;
  color: #fff;
  border-radius: 4px;
  margin-top: 5px;
}
.good-price-con .desc {
  text-align: left;
  font-size:16px;
  color: #333333;
  height: 48px;
  font-weight: bold;
}
.good-price-con .bigger{
  font-size: 18px;
  height: 54px;
}
.touxiang_name {
  height: 80px;
  margin-bottom: 20px;
}

.touxiang_name .touxiang {
  float: left;
  height: 80px;
}
.ivu-avatar>img {
  height: 100%!important;
}
.good-price-con {
  width: 196px;
  padding-top: 8px;
}
.per_gaoxiao {
  flex-shrink: 0;
  height: 320px;
  width: 375px;
  border: 1px solid #f5f5f5;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding: 30px;
}
</style>
