<!--
 * @Author: liu
 * @Date: 2021-03-31 11:22:33
 * @LastEditTime: 2021-04-02 11:58:43
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \cuip-nuxt\components\index\ScienceServices\SGoodItem.vue
-->
<template>
  <div
    :class="['item-con', isChoosen ? 'is-choosen' : '']"
    @click="tabIndexChange"
  >
    <div :class="['triangle-right', isFirstChoosen ? 'first' : '']"></div>
    <div
      style="
        height: 100%;
        width: 95%;
        margin: 0 auto;
        padding: 15px 10px;
      "
      :class="{'border-bottom': showItemBottomLine }"
    >
      <i :class="['iconfont', data.icon, isChoosen ? 'is-choosen' : '']" style="font-size: 20px;color:#fff"></i>
      <span :class="['title', isChoosen ? 'choosen-title' : '']" style="margin-left: 10px">{{data.name}}</span>
<!--      <div style="margin-left: 15px;margin-top: 5px">-->
<!--        <span :class="['sub-title', isChoosen ? 'is-choosen' : 'moren-color']" v-if="data.showSubTitle" style="margin-left: 10px;color:white;padding:0 5px;width:auto;border-bottom:1px solid white;cursor: pointer">{{data.subTitle}}</span>-->

<!--      </div>-->
<!--      <div class="label-list dp-flex flex-between flex-wrap" v-if="data.showLabel">-->
      <div class="label-list dp-flex flex-between flex-wrap">
        <span :class="[isChoosen ? 'choose_span': '', 'subtitle']" v-for="item in data.labels" :key="item.id" @click.stop="chooseItem(item)">{{item.name}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "psTabFloorItem",
  data(){
    return {
      nowItem:"",
    }
  },
  mounted() {

    this.nowItem = this.data.labels[0]
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        name: "",
        subTitle: "",
        labels: [],
        icon: '',
        showLabel: true,
        showSubTitle: true
      })
    },
    config: {
      type: Object,
      default: () => ({
        index: 0,
        itemNum: -1,
        tabIndex: 0
      })
    }
  },
  computed: {
    showItemBottomLine() {
      // let cf = this.config;
      // return !this.isChoosen && cf.index !== cf.itemNum - 1
      return false;
    },
    isChoosen() {
      return this.config.index === this.config.tabIndex
    },
    isFirstChoosen() {
      return this.config.tabIndex === 0
    }
  },
  methods: {
    chooseItem(item) {
      this.nowItem = item
      this.tabIndexChange();
      this.$emit('chooseItem', item.objs || []);
    },
    chooseItemFromTitle() {
      this.$emit('chooseItem', this.data.labels[0].objs || []);
    },
    tabIndexChange(event) {
      this.$emit('tabIndexChange', this.config.index);
      if(event) {
        this.chooseItemFromTitle();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.moren-color {
  color: rgb(102, 102, 102)!important;
}
.border-bottom{
  border-bottom: 1px solid rgb(232, 232, 232);
}
.triangle-right{
  border-style: solid;
  border-width: 14px 14px 14px 14px;
  border-color: transparent white transparent transparent;
  width: 0px;
  height: 0px;
  position: absolute;
  display: none;
}
.item-con{
  position: relative;
  flex: 1;
  width: 100%;
  cursor: pointer;
  &.is-choosen{
    background: rgba(0, 0, 0, 0.3);
    .triangle-right{
      top: 20%;
      right: 0;
      display: block;
      &.first{
        top: 35%;
      }
    }
  }
  .choosen-title {
    font-size: 20px;
  }
  .choose_span {
    font-size: 16px;
  }
}
.title{
  color: #fff;
  font-size: 18px;
}
.subtitle {
  cursor:pointer;
  margin-left: 8px;
  color: #fff;
  font-size:14px;
}
.label-list{
  padding-left: 20px;
  padding-top: 5px;
  width: 196px;
}
</style>
