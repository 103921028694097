<template>
  <!--店铺列表-->
  <div>
    <div style="height: 50px;background-color: white" class="service_div">
      <div style="width: 1180px;margin: 0 auto;height: 100%;border-bottom: 1px solid rgb(220, 220, 220)">
        <span style="margin-right: 80px;color:#333;">店铺类型</span>
        <span v-for="(item,index) in storeType" :key="index" @click="selectStoreType(index)"
              :class="{'select_span':storeTypeChoice == index}">{{item.name}}</span>
      </div>
    </div>
    <div class="center" id="order"
         style="margin-top: 10px;background-color: white;height: 50px;line-height: 50px;font-size: 14px">
              <span style="padding-left: 10px;" @click="chooseMoRen()"
                    :class="{'active' : shopparams.sortField==''}">默认</span>
      <span @click="choosePATENT()"
            :class="[{'active' : shopparams.sortField=='PATENT'},{'activeASC' : shopparams.sortField=='PATENT' && shopparams.sortOrder=='ASC'}]">专利数<span><icon
        class="iconfont iconjiang"></icon></span><span><icon
        class="iconfont iconsheng"></icon></span></span>
      <span @click="chooseGOODS()"
            :class="[{'active' : shopparams.sortField=='GOODS'},{'activeASC' : shopparams.sortField=='GOODS' && shopparams.sortOrder=='ASC'}]">商品数<span><icon
        class="iconfont iconjiang"></icon></span><span><icon
        class="iconfont iconsheng"></icon></span></span>
      <span @click="chooseATTENTION()"
            :class="[{'active' : shopparams.sortField=='ATTENTION'},{'activeASC' : shopparams.sortField=='ATTENTION' && shopparams.sortOrder=='ASC'}]">关注度<span><icon
        class="iconfont iconjiang"></icon></span><span><icon
        class="iconfont iconsheng"></icon></span></span>
    </div>


    <div class="center"
         style="height: 820px;margin-top: 10px;display: flex;justify-content:flex-start;flex-wrap: wrap;background-color: rgb(250,250,250)"
         id="service_list">
      <div v-for="(item,index) in storeList" :key="index"
           style="height: 260px;width: 230px;;background-color: white;padding: 0 10px;margin: 0 5px;" class="per-detail-hover" @click="gotostore(item.id,item.shopTypeId)">
        <div style="height: 120px;padding: 20px 0px">
          <!--          专家-->
          <div v-if="item.shopTypeId==3" style="float: left">
            <Avatar :src="item.imageUrl" size="70" style="margin: 0 20px 0 auto;background-color:#3377FF;">
              {{ item.realName.length > 2 ? item.realName.substring(1) : item.realName }}
            </Avatar>
          </div>
          <!--          高校-->
          <div v-if="item.shopTypeId==2" style="float: left">
            <Avatar v-if="item.imageUrl" :src="item.imageUrl" icon="ios-person"
                    style="margin: -10px auto 0;width: 100px;height: 90px"/>
            <Avatar v-else size="70" style="margin: 0 auto;margin-right:20px">U</Avatar>
          </div>
          <div
            style="float: left;margin-left: 5px;width: 100px;display: flex;flex-flow: column;justify-content: center;height: 70px;">
            <div><span style="font-size: 18px">{{item.realName}}</span></div>
            <div style="font-size: 14px">
              <span>{{item.pa}}</span>
            </div>
          </div>
        </div>
<!--        <div style="height: 90px;background-color: #F7F8FC;padding: 20px 10px;margin-bottom: 20px">-->
<!--          <div style="font-size: 14px;margin-bottom: 10px;height: 20px;">-->
<!--            <div style="float: left"><span style="color: #666666">{{item.shopTypeId==3?'专家':'高校'}}专利数</span></div>-->
<!--            <div style="float: right"><span style="color: #666666">{{item.patentCount}}件</span></div>-->
<!--          </div>-->
<!--          <div style="font-size: 14px;height: 20px;">-->
<!--            <div style="float: left"><span style="color: #666666">{{item.shopTypeId==3?'专家':'高校'}}商品数</span></div>-->
<!--            <div style="float: right"><span style="color: #666666">{{item.goodsCount}}件</span></div>-->
<!--          </div>-->

<!--        </div>-->
        <div style="height: 45px;font-size: 14px">
              <span v-if="item.direction" style="line-height: 25px" class="liang_hang_sheng">
                专业领域：{{item.direction}}
              </span>
        </div>
        <div class="shopbtn" @click="gotostore(item.id,item.shopTypeId)">进入店铺</div>
      </div>
    </div>
    <div STYLE="overflow: hidden;width: 1200px;margin: 0 auto;">
      <Page :total="totalNum" :current="shopparams.pageNum" :page-size="shopparams.pageSize" show-elevator
            style="margin-top: 30px;float: right;margin-bottom: 30px" @on-change="changePage"/>
    </div>
  </div>
</template>

<script>
  import {
    getStoreList,
  } from "../../plugins/api/storeList";

  export default {
    name: "storeList",
    data() {
      return {
        storeType: [
          {type: '', name: '全部分类'},
          {type: 3, name: '专家店铺'},
          {type: 2, name: '高校店铺'}
        ],
        storeTypeChoice: 0,
        shopparams: {
          shopTypeId: '',
          sortField: '',//排序字段(ATTENTION:关注度；PATENT:专利数；GOODS:商品数)
          sortOrder: '',//排序方式(DESC降序、ASC升序)
          pageSize: 15,
          pageNum: 1,
        },
        totalNum: 0,
        storeList: [],
      }
    },
    watch: {
      $route: {
        immediate: true, // 一旦监听到路由的变化立即执行
        handler(to, from) {
          if (JSON.stringify(to.params) !== '{}') {
            this.selectStoreType(to.query.tab)
          } else {
            this.getstoreList()
          }
        },
      }
    },
    methods: {
      chooseMoRen(){
        this.shopparams.sortField = "";
        this.shopparams.sortOrder = "";
        this.getstoreList();
      },
      //店铺列表
      getstoreList() {
        getStoreList(
          this.shopparams
        ).then((res) => {
          if (res.code === 0) {
            this.storeList = res.result.list
            this.totalNum = res.result.total
          }
        })
      },
      //进入店铺
      gotostore(shopId, shopTypeId) {
        let path
        if (shopTypeId == 3) {
          path = 'experts'
        } else {
          path = 'colleges'
        }
        this.$router.push({
          path: path,
          query: {
            shopId
          }
        })
      },
      //切换tab
      selectStoreType(index = 0) {
        this.shopparams.shopTypeId = this.storeType[index].type
        this.storeTypeChoice = index
        this.shopparams.pageNum = 1
        this.getstoreList()
      },
      //排序
      chooseGOODS() {
        if (this.shopparams.sortField == 'GOODS') {
          this.shopparams.sortOrder = this.shopparams.sortOrder == 'ASC' ? 'DESC' : 'ASC';
        } else {
          this.shopparams.sortField = 'GOODS';
          this.shopparams.sortOrder = 'DESC'
        }

        this.getstoreList();
      },
      choosePATENT() {
        if (this.shopparams.sortField == 'PATENT') {
          this.shopparams.sortOrder = this.shopparams.sortOrder == 'ASC' ? 'DESC' : 'ASC';
        } else {
          this.shopparams.sortField = 'PATENT';
          this.shopparams.sortOrder = 'DESC'
        }
        this.getstoreList();
      },
      chooseATTENTION() {
        if (this.shopparams.sortField == 'ATTENTION') {
          this.shopparams.sortOrder = this.shopparams.sortOrder == 'ASC' ? 'DESC' : 'ASC';
        } else {
          this.shopparams.sortField = 'ATTENTION';
          this.shopparams.sortOrder = 'DESC'
        }
        this.getstoreList();
      },
      // 翻页
      changePage(index) {
        this.shopparams.pageNum = index;
        this.getstoreList();
      },
    }
  }
</script>

<style scoped lang="scss">
.per-detail-hover:hover {
  box-shadow: 6px 6px 20px -6px rgba(0, 0, 0, 0.3);
}
  /deep/ .ivu-page-item-active {
    background-color: #2d8cf0;
    /*background-color: #fc7c40;*/
    color: white !important;
    border: none;
  }

  /deep/ .ivu-page-item-active a {
    color: white !important;
  }

  .iconfont {
    font-size: 13px;
    vertical-align: -0.05rem !important;
  }

  .zl_header .per_header:nth-child(3), .zl_header .per_header:nth-child(6) {
    border-right: 1px solid rgb(232, 232, 232) !important;
  }

  .left_desc {
    padding-left: 17px;

    & span {
      line-height: 30px;
    }
  }

  #order > span {
    display: inline-block;
    margin-right: 20px;
    cursor: pointer;
  }

  .per_header {
    padding: 20px;
  }

  .zl_header .per_header:nth-child(4), .zl_header .per_header:nth-child(5), .zl_header .per_header:nth-child(6) {
    margin-top: 4px !important;
  }

  .service_div {
    font-size: 14px;
    line-height: 50px;

    & span {
      display: inline-block;
      height: 50px;
      margin-right: 40px;
      cursor: pointer;
      color: #666;
    }
  }

  .rmzl_content_real {
    padding: 20px 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    text-align: center;
    flex-wrap: wrap;
    align-items: center;

  }

  .dpyx {
    & span {
      margin: 0 2px;
    }
  }

  .san_hang_sheng {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .liang_hang_sheng {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .si_hang_sheng {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  .rmzl_title_price .desc {
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;;
    overflow: hidden;
  }

  .rmzl_image {
    width: 150px;
    height: 180px;
    float: left;
  }

  .rmzl_title_price {
    width: 228px;
    height: 180px;
    line-height: 1;
    float: left;
    box-sizing: border-box;
    padding: 20px 15px;
  }

  .div_center {
    width: 100%;
  }

  .center {
    width: 1200px;
    margin: 0 auto;
  }

  .select_span {
    color: #FF6A00 !important;
    border-bottom: 1px solid #FF6A00!important;
  }

  .active {
    color: #FF6A00;
  }

  .active span:last-child {
    color: #333;
  }

  .activeASC span:first-child {
    color: #333;
  }

  .activeASC span:last-child {
    color: #FF6A00;
  }

  .shopbtn {
    height: 25px;
    line-height: 25px;
    text-align: center;
    width: 140px;
    cursor: pointer;
    color: rgb(255, 106, 0);;
    border: 1px solid rgb(255, 106, 0);;
    margin: 0 auto;
    margin-top: 40px
  }

  .shopbtn:hover {
    background: #ff6a00;
    border: 1px solid #ff6a00;
    color: #fff;
  }

  .liang_hang_sheng {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
</style>
