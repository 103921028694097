<template>
    <!--商品列表-->
    <div :style="{backgroundImage:'url('+bgimg+')','background-size':'100% auto'}">
        <!-- <div style="color: #999;font-size: 14px;line-height: 50px;width: 1200px;margin:0 auto;">
            当前位置  <span style="margin: 0 10px">/</span>  专利超市  <span style="margin: 0 10px">/</span> <span style="color: #1890FF">专利列表</span>
        </div> -->
        <div class="center">
          <img src="http://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/public/202208/74ab524353454b23aa89cf3a20be9192.png" style="width:100%" />
        </div>
        <div class="center">
            <!--            专利列表 start -->
            <div style="background: #fff;border-radius: 8px;">
                <!--            排序 start-->
                <!-- <div id="order">
                    <span style="padding-left: 10px;" @click="chooseMoRen()" :class="{'active' : params.sortField==''}">综合</span>
                    <span @click="choosePRICE()"
                          :class="[{'active' : params.sortField=='PRICE'},{'activeASC' : params.sortField=='PRICE' && params.sortOrder=='ASC'}]">价格
                        <Icon type="md-arrow-dropup" />
                        <Icon type="md-arrow-dropdown" />
                    </span>
                    <span @click="chooseATTENTION()"
                          :class="[{'active' : params.sortField=='ATTENTION'},{'activeASC' : params.sortField=='ATTENTION' && params.sortOrder=='ASC'}]">关注度
                        <Icon type="md-arrow-dropup" />
                        <Icon type="md-arrow-dropdown" />
                    </span>
                    <span @click="chooseUPEDTIME()"
                          :class="[{'active' : params.sortField=='UPEDTIME'},{'activeASC' : params.sortField=='UPEDTIME' && params.sortOrder=='ASC'}]">上架时间
                        <Icon type="md-arrow-dropup" />
                        <Icon type="md-arrow-dropdown" />
                    </span>
                </div> -->
                <!--            排序 end-->
                <div class="gd-list">
                    <div v-for="(item,index) in goodsList" :key="index" class="good-item good_choice">
                        <div style="height: 250px;cursor: pointer;" @click="checkGood(item.GKGGH)">
<!--                            <img style="width: 250px;height: 203px;" :src="item.mainImageUrl"></img>-->
                            <img v-if="item.ZLLX == 1" style="width: 250px;height: 250px;" src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/zhuanlipeitu/famingzhuanli.jpg" />
                            <img v-else-if="item.ZLLX == 2" style="width: 250px;height: 250px;" src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/zhuanlipeitu/shiyongxinxing.jpg" />
                            <img v-else style="width: 250px;height: 250px;" src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/zhuanlipeitu/waiguanzhuanli.jpg" />
                        </div>
                        <div style="height: 50px;line-height: 50px;color: #FF6A00">
                            <span style="font-size: 16px">¥ </span>
                            <span style="font-size: 24px">
                              {{ item.JG ? item.JG + '万' : '面议' }}
                            </span>
                        </div>
                        <div class="good-name yi_hang_sheng"  @click="checkGood(item.GKGGH)">
                          {{ item.ZLMC }}
                        </div>
                        <div class="good-label">
                            <div style="float: left;width:182px" class="ellips" :title="item.ZLQR"><span>{{ item.ZLQR }}</span></div>
                            <div style="float: right;">
                              {{ getPatentType(item.ZLLX) }}
                            </div>
                        </div>

                        <!-- <div class="good-btn">
                            <div class="consult" @click="openZoosUrl()">
                                立即咨询
                            </div>
                            <div class="buybtn" @click="jumpAction(1,item.id)">立即购买
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <!--            专利列表 end -->
            <!--            分页-->
            <div STYLE="overflow: hidden">
                <Page :total="totalNum" :page-size="params.pageSize" show-elevator
                      style="margin-top: 30px;float: right;margin-bottom: 30px" @on-change="changePage"/>
            </div>
        </div>

    </div>
</template>

<script>
    import bgimg from "@/assets/image/bgimg.png";
    import {
      queryLisense
    } from "@/plugins/api/outerApi";
    import {errorToast} from "../../plugins/tools/util";

    export default {
        name: "lisenseList",
        data() {
            return {
                notFirst: true,
                patentType: [
                    {type: '', name: '全部分类'},
                    {type: 'INVENTION', name: '发明专利'},
                    {type: 'UTILITY', name: '实用新型'},
                    {type: 'APPEARANCE', name: '外观设计'}
                ],
                serviceType: [
                    {type: '', name: '全部分类'},
                    {type: 'TRANSFER', name: '转让'},
                    {type: 'LICENSE', name: '许可'}
                ],
                params: {
                    pageSize: 20,
                    pageNum: 1
                },
                totalNum: 0,
                goodsList: [],
                classify: [],
                classifyTwo: [],
                classifyThree: [],
                classifyOneChoice: -1,
                classifyTwoChoice: -1,
                classifyThreeChoice: -1,
                patentTypeChoice: 0,
                serviceTypeChoice: 0,
                isallclassify: false,//相关分类是否展开
                bgimg: bgimg,
            }
        },
        mounted() {
          this.getGoodsList()
        },
        methods: {
            //在线咨询
            openZoosUrl() {
                openZoosUrl('chatwin');
            },
            //商品列表
            getGoodsList() {
                let params = {}
                for (let i in this.params) {
                    if (this.params[i]) {
                        params[i] = this.params[i]
                    }
                }
                params.pageNum--
                queryLisense(params).then(res => {
                  if (res.code === 200) {
                    const result = JSON.parse(res.data || '{}').result
                    this.goodsList = result.data || []
                    this.totalNum = result.totalRowCount || 0
                    this.$nextTick(() => {
                      document.body.scroll(0,0)
                    })
                  }
                })
            },
            getPatentType(type) {
              if (type == 1) {
                return '发明专利'
              } else if(type == 2) {
                return '外观设计'
              } else {
                return '实用新型'
              }
            },
            // 翻页
            changePage(index) {
                this.params.pageNum = index;
                this.getGoodsList();
            },
            //跳转商品详情页
            checkGood(ans) {
                this.$router.push({
                    name: 'good',
                    query: {
                      tab: 2,
                      ans
                    }
                })
            },
            // 立即购买
            async jumpAction(index, goodId) {
                let json = await goodsRest({goodsId: goodId, num: 1})
                if (json.code == 0) {
                    if (json.result == true) {
                        this.$router.push({
                            name: 'good',
                            query: {
                                goodId: goodId,
                                action: index
                            }
                        })
                    } else {
                        errorToast(json.message)
                    }
                } else {
                    errorToast(json.message)
                }

            }
        }
    }
</script>


<style scoped lang="scss">
    /deep/ .ivu-page-item-active {
        background-color: #2d8cf0;
        color: white !important;
        border: none;
    }

    /deep/ .ivu-page-item-active a {
        color: white !important;
    }

    .iconfont {
        font-size: 13px;
        vertical-align: -0.05rem !important;
    }

    .zl_header .per_header:nth-child(3), .zl_header .per_header:nth-child(6) {
        border-right: 1px solid rgb(232, 232, 232) !important;
    }

    .left_desc {
        padding-left: 17px;

        & span {
            line-height: 30px;
        }
    }

    #order {
        margin-top: 10px;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        border-radius: 8px 8px 0px 0px;
        background: #F5F5F5;
    }

    #order > span {
        display: inline-block;
        cursor: pointer;
        width: 90px;
        text-align: center;
        color: #999;
        position: relative;
    }

    .per_header {
        padding: 20px;
    }

    .zl_header .per_header:nth-child(4), .zl_header .per_header:nth-child(5), .zl_header .per_header:nth-child(6) {
        margin-top: 4px !important;
    }

    .service_div {
        font-size: 14px;
        line-height: 32px;
        margin-top: 9px;

        & span {
            display: inline-block;
            height: 32px;
            margin-right: 20px;
            cursor: pointer;
            color: #333;
            padding: 0 10px;
        }
    }

    .rmzl_content_real {
        padding: 20px 15px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        text-align: center;
        flex-wrap: wrap;
        align-items: center;

    }

    .dpyx {
        & span {
            margin: 0 2px;
        }
    }

    .san_hang_sheng {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    .yi_hang_sheng {
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
    }

    .liang_hang_sheng {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .si_hang_sheng {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }

    .rmzl_title_price .desc {
        text-align: left;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;;
        overflow: hidden;
    }

    .rmzl_image {
        width: 150px;
        height: 180px;
        float: left;
    }

    .rmzl_title_price {
        width: 228px;
        height: 180px;
        line-height: 1;
        float: left;
        box-sizing: border-box;
        padding: 20px 15px;
    }

    .div_center {
        width: 100%;
    }

    .center {
        width: 1200px;
        margin: 0 auto;
        padding-top: 20px;
    }

    .select_span {
        text-align: center;
        background: #1890FF;
        border-radius: 4px;
        color: #fff !important;
    }

    #order i:first-child{
        position: absolute;
        top: 10px;
    }

    #order i:last-child{
        position: absolute;
        top: 17px;
    }

    .active {
        color: #1890FF!important;
        background: #FFFFFF;
        border-radius: 8px 8px 0px 0px;
    }

    .active i:first-child {
        color: #999;

    }

    .active i:last-child {
        color: #1890FF;
    }

    .activeASC i:first-child {
        color: #1890FF;
    }

    .activeASC i:last-child {
        color: #999;
    }

    .consult {
        float: left;
        text-align: center;
        line-height: 36px;
        cursor: pointer;
        width: 120px;
        height: 36px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #1890FF;
        color: #1890FF;
    }

    .buybtn {
        float: right;
        text-align: center;
        line-height: 36px;
        cursor: pointer;
        width: 120px;
        height: 36px;
        background: #1890FF;
        color: #fff;
        border-radius: 4px;
    }

    .good-item {
        width: 270px;
        height: 385px;
        background: #FFFFFF;
        border-radius: 8px;
        border: 1px solid #E5E8ED;
        padding: 10px;
    }

    .good_choice:hover {
        box-shadow: 6px 6px 20px -6px rgba(0, 0, 0, .4);
    }

    .good-name {
        font-size: 16px;
        color: #333333;
        height: 22px;
        font-weight: bold;
        width: 250px;
        cursor: pointer;
    }

    .good-label {
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        overflow: hidden;
        color: #999;
        font-weight: 400;
    }

    .gd-list {
        display: grid;
        grid-template-columns: repeat(4, 25%);
        justify-items: center;
        align-items: center;
        padding: 20px 0 40px;
        grid-row-gap: 30px;
    }
</style>
