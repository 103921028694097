<template>
  <div :style="{backgroundImage:'url('+bgimg+')','background-size':'100% auto'}">
    <div style="width: 1200px;margin:0 auto;padding-bottom: 30px">
    <div style="color: #999;font-size: 14px;line-height: 50px;">
      当前位置 <span style="margin: 0 10px">/</span> 科技服务 <span style="margin: 0 10px">/</span> <span
            style="color: #1890FF">高校店铺</span>
    </div>
    <!--            店铺简介 start-->
    <div style="padding: 20px;background: #fff;border-radius: 8px;display: flex;">
      <div style="border-radius: 8px">
        <Avatar v-if="!shopDetal.imageUrl" icon="ios-person" size="70" style="margin: 30px auto"/>
        <img v-else :src="shopDetal.imageUrl" style="width: 100px;height: 90px" alt="">
      </div>
      <div v-if="shopDetal" style="width: 780px;margin-left: 24px;">
        <div style="font-size: 18px;color: black">{{ shopDetal.name }}</div>
        <div style="font-size: 16px;color: #999;line-height: 22px;margin-top: 16px;">{{shopDetal.introduction |ellipsis }}</div>
      </div>
      <div style="text-align: center;margin-left: auto;">
        <div style="height: 50px;line-height: 50px;text-align: right;cursor: pointer" @click="changeshow">
          <span v-if="isshow">收起</span><Icon v-if="isshow" type="ios-arrow-down" />
          <span v-if="!isshow">展开</span><Icon v-if="!isshow" type="ios-arrow-forward" />
        </div>
        <div class="zx_btn" @click.stop="openZoosUrl()">立即咨询</div>
        <div style="margin-top: 10px;color: #576B95;font-size: 12px">点击按钮即可咨询</div>
      </div>
    </div>
      <img v-if="shopDetal.enterpriseLicenseUrl" :src="shopDetal.enterpriseLicenseUrl" style="width: 100%;margin: 20px 0" alt="">
    <!--            店铺简介 end-->
      <!--            专利列表 start -->
      <div v-if="patentList.length" style="margin-top: 20px;border-radius: 8px;background: #fff;">
        <div style="height: 60px;line-height: 60px;padding: 0 20px;border-bottom: 1px dashed #E5E8ED;display: flex;">
          <span style="color: #333;font-size: 24px;font-weight: bold;">店铺专利</span>
          <img src="~@/assets/image/patent/recommend.png" style="width:68px;height:24px;margin: 18px 10px"
               alt=""/>
        </div>
        <div class="gd-list">
          <div v-for="(item,index) in patentList" :key="index" class="per-detail-hover gd-item">
            <div style="height: 250px" @click="seeDetail(item)">
              <!--                            <img src="~@/assets/image/patent/patent-default.png" style="height: 200px;width: 230px" alt="">-->
              <img v-if="item.type == '发明专利'" style="width: 250px;height: 250px;"
                   src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/zhuanlipeitu/famingzhuanli.jpg"/>
              <img v-else-if="item.type == '实用新型'" style="width: 250px;height: 250px;"
                   src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/zhuanlipeitu/shiyongxinxing.jpg"/>
              <img v-else style="width: 250px;height: 250px;"
                   src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/zhuanlipeitu/waiguanzhuanli.jpg"/>
            </div>
            <div style="height: 160px;">
              <div style="height: 50px;line-height: 50px;display: flex;justify-content: space-between">
                <span style="font-size: 20px;color: #FF4040;">暂无报价</span>
                <span style="color: #999;font-size: 14px;">{{ item.type }}</span>
              </div>
              <div style="height: 40px">
                              <span style="font-size: 16px;line-height: 20px;color: black;color: #333;font-weight: bold"
                                    class="liang_hang_sheng">
                                <div v-html="item.ti" class="html-box"></div>
                              </span>
              </div>
              <!--                            <div style="font-size: 12px;margin: 10px 0;overflow: hidden;height: 20px">-->
              <!--                                <div style="float: left"><span>{{ item.ph }}</span></div>-->
              <!--                                <div style="float: right;color: rgb(255, 106, 0);">{{ item.type }}</div>-->
              <!--                            </div>-->

              <div style="margin-top: 14px">
                <div class="consult" @click="openZoosUrl()">
                  立即咨询
                </div>
                <div class="buybtn" @click.stop="seeDetail(item)">立即购买
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div v-else style="min-height:calc(100vh - 590px)"></div>

        <!--            专利列表 end -->

      <!--分页 start-->
      <div style="height: 70px" v-if="patentList.length>16">
        <Page :total="totalNum" :page-size="patentparams.pageSize" show-elevator
              style="margin-top: 20px;float: right;" @on-change="changePage"/>
      </div>
      <!--分页 end-->
    </div>
  </div>
</template>

<script>
  import {
    getpatentbyexpertList,
    getshopDetail,
    sendComment,
    shopCommentList
  } from "@/plugins/api/Shop";
  import {checkLogin} from "@/plugins/api/loginApi";
  import {successToast} from "@/plugins/tools/util";
  import bgimg from "@/assets/image/bgimg.png";

  let vm = {};
  export default {
    name: "experts",
    filters: {
      ellipsis(value) {
        if (!value) return ''
        if (!vm.isshow && value.length > 140) {
          return value.slice(0, 140) + '...'
        }
        return value

      }
    },
    data() {
      vm = this
      return {
        shopId: '',
        patentparams: {
          pageSize: 16,
          pageNum: 1,
        },
        patentList: [],
        totalNum: 0,
        shopDetal: [],
        textareaVal: '',
        commentparams: {
          pageNum: 1,
          pageSize: 8,
        },
        commentList: [],
        commentListTotal: 0,
        bgimg:bgimg,
        isshow:false,//是否展开
      }
    },
    watch: {
      $route: {
        immediate: true, // 一旦监听到路由的变化立即执行
        handler(to, from) {
          if (JSON.stringify(to.params) !== '{}') {
            if (!to.query.shopId) {
              this.$router.push({name: 'list.vue'})
            }
            this.shopId = to.query.shopId
            this.getpatentbyexpertList()
            this.getshopDetail()
            this.shopCommentList()
          }
        },
      }
    },
    methods: {
      seeDetail(item){
        let routeUrl = this.$router.resolve({
          path: "/home/ResourceDetail",//新页面地址
          query: { an: item.an }//携带的参数
        });
        window.open(routeUrl.href, "_blank");
      },
      shopCommentList() {
        shopCommentList({shopId: this.shopId, ...this.commentparams}).then(res => {
          if(res.code === 0){
            this.commentListTotal = res.result.total;
            this.commentList = res.result.list

          }
        })
      },
      //咨询
      openZoosUrl() {
        openZoosUrl('chatwin');
      },
      getpatentbyexpertList() {
        getpatentbyexpertList({'shopId': this.shopId, ...this.patentparams}).then((res) => {
          if (res.code === 0) {
            this.patentList = res.result.list
            this.totalNum = res.result.total
          }
        })
      },
      //专家详情
      getshopDetail() {
        getshopDetail({
          shopId: this.shopId
        }).then((res) => {
          if (res.code === 0 && res.result != null) {
            this.shopDetal = res.result
          }
        })
      },
      // 翻页
      changePage(index) {
        this.patentparams.pageNum = index;
        this.getpatentbyexpertList();
      },
      //咨询弹窗
      returnRoom: function () {
        const state = checkLogin(this.$router)
        if (!state) {
          return
        }

        this.$Modal.confirm({
          draggable: true,
          onOk: async () => {
            this.sendComment()
          },
          render: (h) => {
            return h('div',
              [h('div', {
                style: "margin-bottom:20px;vertical-align: middle;font-size: 16px;color: #17233d;font-weight: 700;",
              }, '咨询内容'),
                h('Input', {
                  style: "width:100%;",
                  props: {
                    value: this.textareaVal,
                    autofocus: true,
                    placeholder: '请输入咨询内容',
                    type: 'textarea',
                    autosize: true
                  },
                  on: {
                    input: (val) => {
                      this.textareaVal = val;
                    }
                  }
                })
              ])
          }
        })
      },
      //发送咨询
      sendComment() {
        sendComment({
          shopId: this.shopId,
          reviewContent: this.textareaVal
        }).then(res => {
          if (res.code === 0){
            this.textareaVal = ""
            successToast("咨询成功")
          }

        })
      },
      //咨询上一页
      lastpre() {
        if (this.commentparams.pageNum > 1) {
          this.commentparams.pageNum = this.commentparams.pageNum - 1
          this.shopCommentList()
        }
      },
      //咨询下一页
      nextpre() {
        if (this.commentparams.pageNum * this.commentparams.pageSize <= this.commentListTotal) {
          this.commentparams.pageNum = this.commentparams.pageNum - 0 + 1
          this.shopCommentList()
        }
      },
      //展开
      changeshow(){
        this.isshow = !this.isshow
      }
    }
  }
</script>

<style scoped>
  .msgbtn {
    margin: 0 auto;
    height: 25px;
    width: 90px;
    text-align: center;
    line-height: 25px;
    border: 1px solid black;
    cursor: pointer;
  }
  .msgbtn:hover {
    background-color: #ff6a00;
    color: white!important;
    border: 1px solid #ff6a00;
  }
  .per-detail-hover:hover {
    box-shadow: 6px 6px 20px -6px rgba(0, 0, 0, 0.3);
  }
  /*.msgbtn:hover {*/
  /*  background: #C51D2B;*/
  /*  border: 1px solid #C51D2B;*/
  /*  color: #fff;*/
  /*}*/

  .liang_hang_sheng {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }



  .zx_btn {
    width: 112px;
    text-align: center;
    line-height: 48px;
    height: 48px;
    background: #1890FF;
    border-radius: 4px;
    color: #fff;
    font-size: 18px;
  }

  .gd-list {
    display: grid;
    grid-template-columns: repeat(4, 25%);
    justify-items: center;
    align-items: center;
    padding: 20px 0 40px;
    grid-row-gap: 30px;
  }

  .gd-item {
    height: 425px;
    background-color: white;
    width: 270px;
    padding: 10px 10px 20px;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    border: 1px solid #E5E8ED;
  }

  .consult {
    float: left;
    text-align: center;
    line-height: 36px;
    cursor: pointer;
    width: 120px;
    height: 36px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #1890FF;
    color: #1890FF;
  }

  .buybtn {
    float: right;
    text-align: center;
    line-height: 36px;
    cursor: pointer;
    width: 120px;
    height: 36px;
    background: #1890FF;
    color: #fff;
    border-radius: 4px;
  }
</style>
<style>
  textarea.ivu-input {
    height: 120px !important;
  }
</style>
