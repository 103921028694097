import {get,post,del,put} from '../http/http'
// const baseUrl = 'https://ip.jsipp.cn'
// const app_id = '0fd448784bce47d380cc3a8bf9a4b024'
// const app_secret = 'bba08d7fc2da46f6af470d1e070de26b'
// const userid = 'c37f644e09bb43ee958ecbca98b380e8'
import baseUrl, {qxtUrl, storebaseUrl} from "../http/baseUrl";

// 权限认证接口,目前token只能使用一次，每次调用api需要重新获取
// export const outerAuth = () => {
//   return post(`${baseUrl}/api/oauth2/token`, {
//     app_id,
//     app_secret,
//     grant_type: 'ip',
//     userid
//   }, { hideLoading: true })
// }

// export const queryLisense = (query) => {
//   const data = {
//     ...query,
//     returnField: 'GMJJML'
//   }
//   return outerAuth().then(res => {
//     return post(`${baseUrl}/api/dx/ecafc8e576a944cf8c31f427f7ed3a2e/queryData?access_token=${res.access_token}`, data)
//   }).catch(ex => {
//     return ex
//   })
// }

export const queryLisense = (params) => {
  return get(`${qxtUrl}/dp/ips`, params, { hideLoading: true })
}

export const queryLisenseDetail = (params) => {
  return get(`${qxtUrl}/dp/ipDetail`, params, { hideLoading: true })
}


export const queryPatent = (params) => {
  return get(`${storebaseUrl}/v1/patent/search`, params, { hideLoading: true })
}