<!--
 * @Author: liu
 * @Date: 2021-03-31 11:22:33
 * @LastEditTime: 2021-04-01 16:25:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \cuip-nuxt\components\index\ScienceServices\SGoodItem.vue
-->
<template>
  <div :class="['good-con', type==='patent' ? 'patent-con': 'service-con']" @click="goDetail(data.id)">
    <image-loader v-if="type!='patent'" :width="199" :height="106" :src="data.pic" :mode="'cover'" style="margin-bottom:8px;border-radius:4px" />
    <div class="rmzl_title_price">
      <div style="color: #333333;line-height:20px;font-size: 14px;font-weight: bold;" class="desc ellipse-1">
        {{data.name}}
      </div>
      <div v-if="type==='patent'" >
        <div style="font-size:12px;color:#666666;margin-top: 10px;text-align: left;line-height: 20px;font-size:12px">
          <div>专利号：{{data.patent.an}}</div>
          <div>专利类型：{{data.patent.type}}</div>
          <div class="ellipse-1">所在店铺：{{data.patent.pa}}</div>
        </div>
        <div style="color: #FF5200;font-size: 23px;margin-top:30px;text-align: left;">
          <span style="vertical-align: text-top;"><span style="font-size:16px;color:#FF5200">￥</span>{{data.price}}</span>
          <span class="consult-btn" @click.stop="goConsult">立即咨询</span>
        </div>
      </div>
      <div v-else>
        <span style="color: #FF5200;font-size: 23px;margin-top:12px;"><span style="font-size:16px;color:#FF5200">￥</span>{{data.price}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SGoodItemTop",
  props: {
    data: {
      type: Object,
      default: () => ({
        id: 0,
        name: '',
        subTitle: '',
        pic: '',
        price: 0,
        patent: null
      })
    },
    detailUrlPath: {
      type: String,
      default: 'serviceDetail'
    },
    type: {
      type: String,
      default: 'service'
    }
  },
  methods:{
    goDetail(id) {
      this.$router.push({
        path: `${this.detailUrlPath}?goodId=${id}`,
      });
    },
    goConsult() {
      openZoosUrl('chatwin');
    }
  }
}
</script>

<style scoped lang="scss">
.good-con{
  border-right: 1px solid rgba(232, 232, 232, 0.5);
  border-bottom: 1px solid rgba(232, 232, 232, 0.5);
  display: flex;
  /*margin-bottom: 20px;*/
  flex-direction: column;
  width: 239px;
  box-sizing: border-box;
  padding:12px 15px;
  height: 195px;
  cursor: pointer;
  &.service-con{
    padding:12px 20px;
  }
}
.good-con:hover {
  box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
}
.rmzl_title_price .desc {
  text-align: left;
}
.consult-btn{
  color: #1890FF;
  font-size: 14px;
  border: 1px solid #1890FF;
  border-radius: 4px;
  padding: 5px 14px;
  margin-left: 18px;
  cursor: pointer;
}
</style>
